import { deserialize } from 'cerialize';
import { Incivilite } from './incivilite.entity';

export class User {

  @deserialize
  public matricule: string = null;

  @deserialize
  public nom: string = null;

  @deserialize
  public prenom: string = null;

  @deserialize
  public directionTerritoriale: string = null;

  @deserialize
  public agenceService: string = null;

  @deserialize
  public groupePrincipal: string = null;

  @deserialize
  public fonction: string = null;

  public canValidate(incivilite: Incivilite) {
    return this.canEdit(incivilite) && incivilite.draft;
  }

  public canEdit(incivilite: Incivilite) {
    return this.isDeclarant(incivilite);
  }

  public canAddComment(incivilite: Incivilite) {
    return (incivilite?.createdBy?.matricule !== this.matricule);
  }

  public isDeclarant(incivilite: Incivilite) {
    return (incivilite?.createdBy?.matricule === this.matricule);
  }

  public toString(): string {
    return this.nom + ' ' + this.prenom;
  }

}
