import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import packageJson from '../../../package.json';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {

  constructor() {
  }

  public get version(): string {
    return packageJson.version;
  }

  public get mobile(): boolean {
    return environment.mobile;
  }

  public get apiBaseUrl(): string {
    return environment.api.baseUrl;
  }

  public get apiEndpoints(): {} {
    return environment.api.endpoints;
  }

}
