import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root',
})
export abstract class EndpointService {

  constructor(private configuration: ConfigurationService) {
  }

  public find(type: string, action: string, params?: {}): string {
    let url = this.configuration.apiEndpoints[type][action];
    for (const key in params) {
      if (Object.hasOwn(params, key)) {
        url = url.replace(`:${key}`, params[key]);
      }
    }
    return this.configuration.apiBaseUrl + url;

  }

}
