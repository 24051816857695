import { endpoints } from './endpoints';

export const environment = {
  environment: 'production',
  mobile: false,
  production: true,
  displayMobileIdField: false,
  api: {
    baseUrl: 'https://api-incivilite.rivp.fr/',
    endpoints,
  },
  pictures: {
    addLogoRIVP: false,
  },
};
