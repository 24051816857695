import { Injectable } from '@angular/core';
import { Deserialize } from 'cerialize';
import { User } from '../models/user.entity';

@Injectable({
  providedIn: 'root',
})
export class ApiTokenService {
  private readonly KEY = 'apiToken';
  private readonly KEY_USER = 'apiUser';
  public user = new User();

  public loadUser() {
    this.user = this.getUser();
  }

  public set(token: string): void {
    localStorage.setItem(this.KEY, token);
  }

  public setUser(user: User = null): void {
    this.user = Deserialize(user, User);
    localStorage.setItem(this.KEY_USER, JSON.stringify(user));
  }

  public get(): string {
    return localStorage.getItem(this.KEY);
  }

  public getUser(): User {
    return Deserialize(JSON.parse(localStorage.getItem(this.KEY_USER)), User);
  }

  public isDefined(): boolean {
    return (!!this.get()) && (this.get() !== 'undefined');
  }

  public remove(): void {
    localStorage.removeItem(this.KEY);
    localStorage.removeItem(this.KEY_USER);
  }
}
