import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ErrorHandler, LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { DocumentViewer } from "@awesome-cordova-plugins/document-viewer/ngx";
import { FileOpener } from "@awesome-cordova-plugins/file-opener/ngx";
import { FileTransfer } from "@awesome-cordova-plugins/file-transfer/ngx";
import { File } from "@awesome-cordova-plugins/file/ngx";
import { ImagePicker } from "@awesome-cordova-plugins/image-picker/ngx";
import { SplashScreen } from "@awesome-cordova-plugins/splash-screen/ngx";
import { StatusBar } from "@awesome-cordova-plugins/status-bar/ngx";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthGuard } from "./guards/auth-guard.service";
import { TokenInterceptor } from "./interceptors/token.interceptor";
import { NgChartsModule } from "ng2-charts";
import { registerLocaleData } from "@angular/common";
import localeFr from "@angular/common/locales/fr";


registerLocaleData(localeFr);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      platform: {
        desktop: (win) => {
          const isMobile =
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
              win.navigator.userAgent,
            );
          return !isMobile;
        },
      },
    }),
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgChartsModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: ErrorHandler, useClass: ErrorHandler },
    AuthGuard,
    ImagePicker,
    FileOpener,
    File,
    DocumentViewer,
    FileTransfer,
    { provide: LOCALE_ID, useValue: "fr-FR" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
