export const endpoints = {
  login: {
    mobile: 'api/login',        // WSAUTH01
    registration: 'api/register',
  },
  fiches: {
    list: 'api/fiches',
    download: 'api/fiche/download'
  },
  documents: {
    upload: 'api/document/upload',
    download: 'api/document/download'
  },
  groupes: {
    search: 'api/groupe/search',
  },
  natures: {
    list: 'api/natures',
  },
  victimes: {
    list: 'api/victimes',
  },
  interventions: {
    list: 'api/interventions',
  },
  actions: {
    list: 'api/actions',
  },
  incivilites: {
    me: 'api/mes-declarations',
    my_colabs: 'api/declarations-de-mes-collabs',
    create: 'api/create',
    edit: 'api/edit',
    validate: 'api/validate',
    add_comment: 'api/add-comment',
    export: 'api/incivilite.csv',
    export_by_year: 'api/incivilite/export',
    export_repartition: 'api/repartition.csv',
    export_nature_fiche: 'api/nature-fiche.csv',
    declaration: 'api/declaration',
    export_pdf: 'api/declaration/export-pdf'
  },
  charts: {
    total: 'api/total',
    nature_par_type_nature: 'api/nature-par-type-nature',
    incivilites_par_mois: 'api/incivilites-par-mois',
  }
};


