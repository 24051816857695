import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginApi } from '../models/api/login.api';
import { RegisterApi } from '../models/api/register.api';
import { User } from '../models/user.entity';
import { ApiTokenService } from './apiToken.service';
import { EndpointService } from './endpoint.service';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  public mobileKey = 'mobileKey';
  public sso = 'sso';
  public email = 'email';

  public authenticationIsRunning = false;

  public authenticationErrorDetail = '';

  public authenticationPromise: Promise<any>;

  public registerPromise: Promise<any>;

  public user: User = null;

  constructor(private http: HttpClient,
              private endpointService: EndpointService,
              private router: Router,
              private apiTokenService: ApiTokenService,
              private toastService: ToastService) {
  }

  public isAuthenticated(): boolean {
    return this.apiTokenService.isDefined();
  }

  protected loadUrlAuthentication(): string {
    return this.endpointService.find('login', 'mobile');
  }

  protected loadUrlRegistration(): string {
    return this.endpointService.find('login', 'registration');
  }

  public login(redirectIdIncivilite = null) {
    const formData = new FormData();
    formData.append('mobile-key', this.mobileKey);
    formData.append('sso', this.sso);
    this.authenticationIsRunning = true;
    this.authenticationPromise =
      this.http.post(this.loadUrlAuthentication(), formData).toPromise().then(
        (data: LoginApi) => {
          if (((data.coderetour === 0) ||
            (data.coderetour === 0)) && !(data.error && (data.error === 'Warning: Incorrect API Key!' ||
            data.error === 'La clé mobile n\'existe pas et n\'a jamais été enregistrée'))) {
            this.apiTokenService.set(data.apiToken);
            while (this.apiTokenService.get() !== data.apiToken) {
              setTimeout(() => {
                console.log('timeout');
              }, 100);
            }
            this.user = data.user;
            this.apiTokenService.setUser(this.user);
            // TODO à réimplémenter ou à virer
            // this.inciviliteWizardService.initDataAndForm();
            if (redirectIdIncivilite) {
              this.router.navigate([ '/tabs/tab-incivilite/' + redirectIdIncivilite ]);
            } else {
              this.router.navigate([ '/tabs/' ]);
            }
          } else {
            this.authenticationErrorDetail = data.error;
          }
          this.authenticationIsRunning = false;
        },
        (data) => {
          throw new Error('An unexpected error occurred while data loading : ' + data);
        })
        .finally(() => {
          this.authenticationIsRunning = false;
        });
  }

  public register() {
    const formData = new FormData();
    formData.append('app', 'INCIVILITE');
    formData.append('idmobile', this.mobileKey);
    formData.append('email', this.email);
    this.registerPromise =
      this.http.post(this.loadUrlRegistration(), formData).toPromise().then(
        (data: RegisterApi) => {
          if ((data.status === 'status_success') || (data.codeRetour === 0)) {
            this.toastService.presentToast('Enregistrement en cours');
            this.login();
          } else {
            this.toastService.presentToast('Erreur lors de l\'enregistrement : ' + data.error, 'danger');
          }
        },
        (data) => {
          this.toastService.presentToast('Erreur lors de l\'enregistrement : ' + data.error, 'danger');
        })
        .finally(() => {

        });
  }
}
